import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Collection } from '../interfaces/collections';
import { User } from '../interfaces/users';

export type UserStatus = 'pending' | 'loggedIn' | 'loggedOut';
export const tokenAtom = atomWithStorage<string | null>('tokenv2', null);

export const userAtom = atom<User | undefined>(undefined);
export const userCollectionsAtom = atom([] as Collection[]);
export const ownedPhotosAtom = atom<number[] | null>(null);
export const ownedClipsAtom = atom<number[] | null>(null);

type PreAuthData = {
  type: string | null;
  name: string | null;
  email: string | null;
  password: string | null;
  industry: string | null;
  values: string | null;
  visual: string | null;
  referrer: string | null;
  setupPosition: number;
};
export const intialPreAuthData: PreAuthData = {
  type: null,
  name: null,
  email: null,
  password: null,
  industry: null,
  values: null,
  visual: null,
  referrer: null,
  setupPosition: 1,
};
export const userPreAuthDataAtom = atomWithStorage<PreAuthData>('preAuthData', intialPreAuthData);
