import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { isBrowser, scrollAtom } from '../store/store';

export default function useScroll(init = false) {
  const [scroll, setScroll] = useAtom(scrollAtom);

  const onScroll = () => {
    const x = window.pageXOffset;
    const y = window.pageYOffset;
    setScroll((prev) => {
      return {
        ...prev,
        x,
        y,
        direction: prev.y > y ? 'up' : 'down',
        distFromBottom: getDistFromBottom(),
      };
    });
  };

  function getDistFromBottom() {
    return isBrowser ? document.body.offsetHeight - window.innerHeight - window.pageYOffset : 0;
  }

  useEffect(() => {
    if (init) window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      if (init) window.removeEventListener('scroll', onScroll);
    };
  }, [init]);

  return {
    distFromBottom: scroll.distFromBottom,
    hasScrolled: scroll.direction !== undefined,
    scrollDirection: scroll.direction,
    scrollX: scroll.x,
    scrollY: scroll.y,
  };
}
