import { TagReference } from '@/interfaces/tags';

export const featuredTags: TagReference[] = [
  { name: 'people of color', id: 2799 },
  { name: 'women', id: 1655 },
  { name: 'work', id: 64 },
  { name: 'beauty', id: 1239 },
  { name: 'studio', id: 81 },
  { name: 'design', id: 463 },
  { name: 'wellness', id: 1003 },
  { name: 'people', id: 536 },
  { name: 'artist', id: 667 },
  { name: 'digital', id: 425 },
  { name: 'textures', id: 2874 },
  { name: 'fashion', id: 9 },
  { name: 'therapy', id: 4570 },
  { name: 'home & family', id: 4685 },
  { name: 'adventure', id: 206 },
  { name: 'travel', id: 3 },
  { name: 'team', id: 502 },
  { name: 'food & drinks', id: 4683 },
  { name: 'architecture', id: 698 },
];
