import Head from 'next/head';
import React from 'react';

export default function HtmlHead() {
  return (
    <Head>
      <link rel="shortcut icon" href="favicon.ico" />
      <title>DEATH TO STOCK®</title>
      {/* <meta name="pinterest" content="nopin" />

      <meta property="og:url" content="https://app.deathtothestockphoto.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Death to Stock - When stock dies art thrives." />
      <meta
        property="og:image"
        content="https://deathtothestockphoto.com/wp-content/uploads/2022/05/dts-og-image.jpg"
      />
      <meta
        property="og:description"
        content="Death to the Stock Photo is a unique stock photo resource for creatives looking to stand out. "
      />
      <meta property="og:site_name" content="Death to the Stock Photo" />

      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://app.deathtothestockphoto.com/" />
      <meta
        name="twitter:title"
        content="Death to Stock - Authentic stock photos for non-stock creatives."
      />
      <meta
        name="twitter:description"
        content="Death to the Stock Photo is a unique stock photo resource for creatives looking to stand out. "
      />
      <meta name="twitter:creator" content="@deathtostock" />
      <meta
        name="twitter:image"
        content="https://deathtothestockphoto.com/wp-content/uploads/2022/05/dts-og-image.jpg"
      /> */}
    </Head>
  );
}
