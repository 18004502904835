import clsx from 'clsx';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { tokenAtom } from '../../store/userStore';
import LoadingSpinner from '../shared/LoadingSpinner';
import DTSLogo from './icons/DTSLogo';
import { analytics } from '../../services/analytics';
import { signOut } from 'next-auth/react';

export default function LoadingComponent() {
  const [speed, setSpeed] = useState('');
  const [token, setToken] = useAtom(tokenAtom);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setSpeed('slow');
    }, 4000);
    const timer2 = setTimeout(() => {
      setSpeed('stopped');
    }, 8000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const handleLogout = () => {
    signOut();
    analytics.track('Logged out');
  };

  return (
    <div className="h-screen w-full relative flex flex-col items-center justify-center">
      <div className="absolute top-[46.5px] left-16">
        <DTSLogo />
      </div>
      <div className="h-6 w-full relative">
        <div
          className={clsx(
            'transition text-center absolute top-0 w-full',
            speed === 'slow' ? 'opacity-100 animate-pulse' : 'opacity-0 pointer-events-none'
          )}
        >
          This is taking longer than usual...
        </div>
        <div
          className={clsx(
            'transition text-center absolute top-0 w-full',
            speed === 'stopped' ? 'opacity-100' : 'opacity-0 pointer-events-none'
          )}
        >
          Something weird has happened, try{' '}
          <a className="text-orange-400 cursor-pointer" onClick={() => handleLogout()}>
            login again
          </a>{' '}
          or{' '}
          <a
            className="text-orange-400 cursor-pointer"
            href="mailto:squad@deathtothestockphoto.com"
          >
            contact support
          </a>
          .
        </div>
      </div>
    </div>
  );
}
