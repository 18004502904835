import React from 'react';
import Button from './shared/Button';

export default function Error() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center pt-32">
      <div className="uppercase font-normal font-adieu leading-[1.4] tracking-[0.15em] text-lg 3xl:text-[22px] 4xl:text-[28px]">
        Oops, You have hit an unexpected error
      </div>
      <div className="">If this happens again please contact support.</div>
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a href="/">
        <Button>Click here to return to home</Button>
      </a>
    </div>
  );
}
