import { useInfiniteQuery } from 'react-query';
import type { SearchOptions } from '@algolia/client-search';
import { searchAlgoliaMedias } from '@/services/algolia';
import { AlgoliaMediaView, AlgoliaVideo } from '@/interfaces/algolia';
import { trpc } from '@/utils/trpc';

export default function useMediaSearch(term: string, searchKey: string, params?: SearchOptions) {
  const defaultParams = {
    page: 0,
    hitsPerPage: 24,
  };
  const queryParams = { ...defaultParams, ...params };

  const facetFilters = params?.facetFilters ? params?.facetFilters.toString() : '';
  const getImgixPhotoMutation = trpc.photos.getImgixPhotos.useMutation();

  const { data, isLoading, isFetchingNextPage, error, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [searchKey, term, facetFilters],
      queryFn: async ({ pageParam = 0 }) => {
        const rawMedias = await searchAlgoliaMedias(term, { ...queryParams, page: pageParam });
        const formatedImgixData =
          rawMedias && rawMedias.hits && rawMedias.hits.length > 0
            ? // @ts-ignore
              ((await getImgixPhotoMutation.mutateAsync(rawMedias)) as SearchResponse<AlgoliaMedia>)
            : [];
        return formatedImgixData;
      },
      getNextPageParam: (lastPage, _allPages) => {
        if (lastPage.page + 1 < lastPage.nbPages) return lastPage.page + 1;
        else return undefined;
      },
      staleTime: 1000,
    });

  let algoliaMedia: AlgoliaMediaView[] = [];

  if (data) {
    const medias = data?.pages?.map((page) => page.hits ?? []).flat() || [];

    const formatedMedias = medias.map((media) => {
      const tagsRaw = JSON.parse(media.tag);
      let tag = [];
      if (tagsRaw && tagsRaw.length > 0) {
        tag = tagsRaw.map((t: string | null) => (t === null ? [] : t.split(','))).flat();
      }
      if (media.media_type === 'photo') {
        return {
          ...media,
          type: media.media_type,
          tag,
        };
      } else if (media.media_type === 'video') {
        const orientation =
          (media as AlgoliaVideo).video_height > (media as AlgoliaVideo).video_width
            ? 'portrait'
            : 'landscape';
        return { ...media, type: media.media_type, orientation: orientation, tag };
      } else {
        return { ...media, type: media.media_type, tag };
      }
    });
    algoliaMedia = formatedMedias as unknown as AlgoliaMediaView[];
  }

  return {
    medias: algoliaMedia,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
    hasNextPage,
  };
}
