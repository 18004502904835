import clsx from 'clsx';
import { useState } from 'react';
import { TagReference } from '../../interfaces/tags';
import { featuredTags } from '@/constants/featuredTags';

const TagList = ({
  onTagSelected,
  onHover,
}: {
  onTagSelected: (tag: TagReference) => void;
  onHover: (tag?: TagReference) => void;
}) => {
  const [hovered, setHovered] = useState<TagReference | null>(null);

  const tags: TagReference[] = featuredTags;

  const handleChange = (tag: TagReference) => {
    onTagSelected(tag);
  };

  const handleHover = (tag: TagReference) => {
    onHover(tag);
    setHovered(tag);
  };

  const handleHoverOut = () => {
    onHover();
    setHovered(null);
  };

  return (
    <div className="flex flex-wrap w-[85%] h-[117px] overflow-visible -ml-6">
      {tags.map((tag) => (
        <p
          key={tag.id}
          onClick={() => handleChange(tag)}
          onMouseEnter={() => handleHover(tag)}
          onMouseLeave={handleHoverOut}
          className={clsx([
            'font-semibold font-adieu leading-none tracking-[0.15em] px-4 py-4 uppercase cursor-pointer',
            "before:content-['('] after:content-[')']",
            'transition-opacity duration-300 before:transition-opacity before:duration-300 after:transition-opacity after:duration-300 ease-out',
            'text-[15px]',
            hovered?.id === tag.id || !hovered?.id ? 'opacity-100' : 'opacity-40',
            hovered?.id === tag.id
              ? 'before:opacity-100 after:opacity-100'
              : 'before:opacity-0 after:opacity-0',
          ])}
        >
          {tag.name}
        </p>
      ))}
    </div>
  );
};

export default TagList;
