export const ADMIN_ROLE_ID = 1;
export const DTS_ASSISTANT_ROLE_ID = 341;
export const EDITORIAL_TAG_ID = 4294;
export const MAX_MOODBOARDS_PER_USER = 10;
export const MAX_BOOKMARKS_PER_MOODBOARD = 75;
export const MAINTENANCE_ERROR_MESSAGE = 'The site is currently under maintenance.';
export const DEFAULT_MAINTENANCE_DESCRIPTION =
  'Experiencing a temporary glitch!\nFull service will be available ASAP';
export const MAINTENANCE_CHECKING_EXCEPTION_ENDPOINT = [
  'users.getAuthenticatedUser',
  'settings.getCurrentMaintenance',
];
export const DTS_PRICING_URL = 'https://www.deathtothestockphoto.com/pricing';
export const DTS_WEBSITE = 'https://www.deathtothestockphoto.com';

export const EVENTS = {
  addedLink: 'Added Link',
  loadedSharedMoodboard: 'Loaded Shared Moodboard',
  loadedMoodboard: 'Loaded Moodboard',
  loadedPublicMoodboard: 'Loaded Public Moodboard',
  copiedMoodboard: 'Copied Moodboard',
  postLinkProviders: 'Post Providers',
  requestedLink: 'Requested Link',
};
