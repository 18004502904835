import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import {
  dontChangeStickyNavAtom,
  minHideHeightAtom,
  showSearchAtom,
  showStickyNavAtom,
} from '../store/uiStore';
import useScroll from './useScroll';

export default function useNavHelper() {
  const [showStickyNav, setShowStickyNav] = useAtom(showStickyNavAtom);
  const [dontChangeStickyNav, setDontChangeStickyNav] = useAtom(dontChangeStickyNavAtom);
  const [showSearch] = useAtom(showSearchAtom);

  const { scrollY } = useScroll();
  const [prevScrollY, setPrevScrollY] = useState(scrollY);
  const [minHideHeight, setMinHideHeight] = useAtom(minHideHeightAtom);
  useEffect(() => {
    setShowStickyNav(() => {
      if (dontChangeStickyNav) {
        setPrevScrollY(scrollY);
        return false;
      }

      const scrolledFar = scrollY < 60 && scrollY > 0;
      const scrollingDownQuickly = scrollY <= prevScrollY;
      const atTop = scrollY === 0;

      setPrevScrollY(scrollY);

      return (
        scrolledFar ||
        scrollingDownQuickly ||
        atTop ||
        showSearch || // we want to keep it open if we are on search
        scrollY <= 0 || // handles route change jumpiness
        prevScrollY <= 0 // handles route change jumpiness
      );
    });
  }, [scrollY, dontChangeStickyNav]);

  return {
    showStickyNav,
    setShowStickyNav,
    dontChangeStickyNav,
    setDontChangeStickyNav,
    setMinHideHeight,
    minHideHeight,
  };
}
