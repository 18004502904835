import { useRouter } from 'next/router';
import { useMemo } from 'react';

const outsideAppPaths = [
  'sign-up/',
  'onboarding/',
  'password/',
  'login',
  'forgot-password',
  '/public/protected',
  '/assets/images/DTS_Moodboard_Public_OG.png',
];
const accountPaths = ['account'];
const checkoutPaths = ['checkout'];
const adminPaths = ['admin'];
const universePaths = ['universe'];
const inMaintenance = ['/maintenance'];
const inPublicMoodbard = '/public/moodboard/[id]';
const inPrivateMoodbarod = '/collection/[id]';
const inPublicPage = ['/public'];

export default function useInApp() {
  const router = useRouter();
  const hasPaths = (paths: string[]) => paths.some((r) => router.pathname.includes(r));
  return useMemo(() => {
    return {
      inApp: !hasPaths(outsideAppPaths), // note inverse - applies for eveything that is not public
      inAccount: hasPaths(accountPaths),
      inCheckout: hasPaths(checkoutPaths),
      inAdmin: hasPaths(adminPaths),
      inUniverse: hasPaths(universePaths),
      inProjects: router.pathname === '/projects',
      inMaintenance: inMaintenance.includes(router.pathname),
      inPublicMoodbard: router.pathname === inPublicMoodbard,
      inPrivateMoodbard: router.pathname === inPrivateMoodbarod,
      inPublicPage: hasPaths(inPublicPage),
    };
  }, [router.pathname]);
}
