import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { colorManagerAtom, showSearchAtom } from '../../store/uiStore';
import { searchTags } from '../../services/algolia';
import { captureException } from '@sentry/nextjs';
import clsx from 'clsx';

const SearchInput = () => {
  const [colorManager] = useAtom(colorManagerAtom);
  const [value, setValue] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState<any[]>([]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const [userHasSelected, setUserHasSelected] = useState(false);
  const router = useRouter();
  const [showSearch, setShowSearch] = useAtom(showSearchAtom);

  const handleClear = () => {
    setValue('');
    setShowSearch(false);
  };

  useEffect(() => {
    setActiveSuggestion(-1);

    if (userHasSelected) return;

    const fetchSuggestions = async () => {
      try {
        const hits = await searchTags(value);
        if (hits.length > 0) {
          setSearchSuggestions(hits);
        }
      } catch (error) {
        captureException(error);
      }
    };

    if (value.length > 2) {
      fetchSuggestions();
    } else {
      setSearchSuggestions([]);
    }
  }, [value, userHasSelected]);

  const handleUserSelectedSuggestion = (suggestion) => {
    setUserHasSelected(true);
    setSearchSuggestions([]);
    setValue('');
    setShowSearch(false);
    router.push('/search?q=' + suggestion.name);
  };

  const onKeyDown = (e) => {
    if (userHasSelected) setUserHasSelected(false);

    // User pressed the enter key
    if (e.keyCode === 13) {
      // setShowSuggestions(false);
      const chosenValue: string = searchSuggestions?.[activeSuggestion]?.name || value;

      setValue(chosenValue);
      setActiveSuggestion(-1);
      handleUserSelectedSuggestion({ name: chosenValue });
    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      const newActiveSuggestion = activeSuggestion - 1;
      setActiveSuggestion(newActiveSuggestion);

      // scroll into view
      const element = suggestionsRef.current?.children[newActiveSuggestion];
      if (element) element.scrollIntoView(false);
    }

    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion + 1 === searchSuggestions.length) {
        return;
      }
      const newActiveSuggestion = activeSuggestion + 1;
      setActiveSuggestion(newActiveSuggestion);

      // scroll into view
      const element = suggestionsRef.current?.children[newActiveSuggestion];
      if (element) element.scrollIntoView(false);
    }
  };

  const ref = useRef<HTMLDivElement>(null);
  //close on clickaway from ref
  useEffect(() => {
    const handleClickAway = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setSearchSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [ref, setShowSearch]);

  return (
    <div
      className={clsx([
        'pt-[12px] pb-[13px] px-4 w-full relative max-w-[300px]',
        searchSuggestions.length > 0 ? 'rounded-t-[22px]' : 'rounded-[22px]',
        colorManager?.text === 'text-black' ? 'bg-[rgba(0,0,0,0.04)]' : 'bg-white',
      ])}
      ref={ref}
    >
      <div
        className="text-black grid gap-2 items-center overflow-hidden"
        style={{ gridTemplateColumns: 'min-content 1fr min-content' }}
      >
        <div className="pt-[1px]">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M12.5 11H11.71L11.43 10.73C12.4439 9.55402 13.0011 8.0527 13 6.5C13 5.21442 12.6188 3.95772 11.9046 2.8888C11.1903 1.81988 10.1752 0.986756 8.98744 0.494786C7.79973 0.00281635 6.49279 -0.125905 5.23192 0.124899C3.97104 0.375703 2.81285 0.994767 1.90381 1.90381C0.994767 2.81285 0.375703 3.97104 0.124899 5.23192C-0.125905 6.49279 0.00281635 7.79973 0.494786 8.98744C0.986756 10.1752 1.81988 11.1903 2.8888 11.9046C3.95772 12.6188 5.21442 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
              fill="black"
            />
          </svg>
        </div>

        <input
          type="text"
          placeholder="Search our images"
          onClick={(e) => setShowSearch(true)}
          className="font-nhg font-normal text-sm 3xl:text-sm 4xl:text-[15px] leading-none text-black focus:outline-none col-span-1 bg-transparent"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={onKeyDown}
          onFocus={() => setShowSearch(true)}
        />

        <div>
          {showSearch && (
            <div onClick={handleClear} className="cursor-pointer">
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 0.5L13 12.5" stroke="#212121" strokeLinejoin="round" />
                <path d="M13 0.5L1 12.5" stroke="#212121" strokeLinejoin="round" />
              </svg>
            </div>
          )}
        </div>
      </div>

      {searchSuggestions.length > 0 && (
        <div className="bg-white text-black px-[42px] absolute left-0 right-0 rounded-b-[22px] py-[10px]">
          <div className="m-x-[20px] bg-black opacity-30 w-full h-[1px] mb-[10px]"></div>
          <ul ref={suggestionsRef}>
            {searchSuggestions.map((hit, idx) => (
              <li
                key={hit.id}
                className={clsx([
                  'cursor-pointer text-gray-400 font-nhg font-normal text-sm leading-none 3xl:text-sm 4xl:text-[15px] py-[4px] hover:text-black',
                  idx === activeSuggestion ? '!text-black' : '',
                ])}
                onClick={() => handleUserSelectedSuggestion(hit)}
              >
                {hit.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
