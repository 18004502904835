import { useAtom } from 'jotai';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { notificationAtom } from '../../store/uiStore';

const Notification = () => {
  const [notification, setNotification] = useAtom(notificationAtom);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (notification?.message && notification.message.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [notification]);

  const clearNotification = () => {
    setShow(false);
    setNotification({ message: '', link: '' });
  };

  return (
    <>
      {show && (
        <div
          className="fixed bottom-12 grid place-items-center w-full z-50"
          style={{
            transform: 'translate3d(0, 0%, 500px)',
          }}
        >
          {notification?.link && notification.link.length > 0 ? (
            <Link href={notification.link ?? ''} passHref className="contents">
              <p
                className="bg-white py-4 px-[10px] rounded-lg text-black animate-slideUp shadow-[0_5px_5px_0px_rgba(0,0,0,0.25)]"
                onClick={clearNotification}
              >
                {notification.emoji ?? '⚡️'} &nbsp;&nbsp; {notification.message}
              </p>
            </Link>
          ) : (
            <p
              className="bg-white py-4 px-[10px] rounded-lg text-black animate-slideUp shadow-[0_5px_5px_0px_rgba(0,0,0,0.25)]"
              onClick={clearNotification}
            >
              {notification.emoji ?? '⚡️'} &nbsp;&nbsp; {notification.message}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default Notification;
