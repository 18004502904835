import { trpc } from '@/utils/trpc';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { adminModeAtom } from '../store/store';
import { isAdmin } from '../utils/isAdmin';

let timeOut;

/**
 * Controls showing and hiding admin mode
 * Admins can double tao shift to toggle admin mode
 * Only one useAdmin hook should be set up as the controller this acts as context
 * @param controller
 * @returns
 */
export default function useAdmin(controller?: boolean) {
  const { data: userData } = trpc.users.getAuthenticatedUser.useQuery(undefined, {
    staleTime: Infinity,
  });

  // handles toggling admin mode
  const [adminMode, setAdminMode] = useAtom(adminModeAtom);

  // The controller invocation of useAdmin will listen to keydown events
  const [shiftCount, setShiftCount] = useState(0);
  useEffect(() => {
    if (!controller) return;
    const handler = (e: KeyboardEvent) => {
      // if not shift reset count
      if (e.key !== 'Shift') return setShiftCount(0);

      // if shift count is already one then lets toggle and reset
      if (shiftCount === 1) {
        setAdminMode((prev) => !prev);
        clearTimeout(timeOut);
        setShiftCount(0);
      }

      // if shift at zero increment and reset after 500ms
      if (shiftCount === 0) {
        setShiftCount(1);
        timeOut = setTimeout(() => setShiftCount(0), 500);
      }
    };
    window.addEventListener('keydown', handler, false);
    return () => window.removeEventListener('keydown', handler, false);
  }, [setAdminMode, controller, shiftCount, userData?.id]);

  const showAdmin = adminMode && userData && isAdmin(userData);

  return showAdmin;
}
