import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

type Props = {
  type?: 'primary' | 'secondary' | 'tertiary' | 'circle' | undefined;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
};

const typeMap = {
  primary:
    'bg-orange-400 border-orange-400 hover:text-white hover:bg-orange-500 hover:border-orange-500',
  tertiary: 'bg-[#A2EE00] border-[#A2EE00] text-black  hover:bg--[#A2EE00] hover:border-[#A2EE00]',
};

const defaultStyles = 'bg-transparent border-white hover:text-orange-400 hover:border-orange-400';

export const baseButtonClasses =
  'overflow-hidden w-fit font-nhg font-normal uppercase leading-[1] tracking-[0.15em] relative inline-block text-sm 3xl:text-[15px] 4xl:text-[16px] transition-all whitespace-nowrap text-white border text-center pt-[12px] px-[30px] pb-[11px] rounded-full justify-center items-center select-none';

const Button = ({
  type = 'primary',
  disabled = false,
  loading = false,
  className,
  children,
  buttonType = 'button',
  onClick = () => {},
}: Props) => {
  const loadingClasses = `!bg-white !border-white !text-white after:flex after:content-[''] after:absolute after:top-[50%] after:left-[50%] after:w-[20px] after:h-[20px] after:ml-[-10px] after:mt-[-10px] after:rounded-full after:border-[2px] after:border-black after:border-t-transparent after:animate-spin cursor-default pointer-events-none`;

  const defaultClasses = clsx(
    baseButtonClasses,
    typeMap[type] ?? defaultStyles,
    disabled
      ? 'bg-[#646464] border-[#646464] cursor-default pointer-events-none'
      : 'cursor-pointer',
    loading ? loadingClasses : '',
    className
  );

  if (type === 'circle') {
    return (
      <div className="w-full relative cursor-pointer" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="186"
          height="79"
          viewBox="0 0 186 79"
          fill="none"
        >
          <ellipse cx="93" cy="39.5" rx="93" ry="39.5" fill="#A2EE00" />
        </svg>
        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[14px] text-black text-nhg text-normal tracking-[0.42px] leading-[130%] w-full text-center">
          {children}
        </span>
      </div>
    );
  }

  return (
    <button
      type={buttonType}
      onClick={onClick}
      className={twMerge(defaultClasses)}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
