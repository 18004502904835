import { users } from '@prisma/client';
import * as Sentry from '@sentry/nextjs';
import flatten from 'flat';
import { useAtom } from 'jotai';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { analytics } from '../../services/analytics';
import { isBrowser, isEditorialMapAtom, isEditorialQueueAtom } from '../../store/store';
import { ownedClipsAtom, ownedPhotosAtom } from '../../store/userStore';
import { trpc } from '../../utils/trpc';

export default function WithAuth({ children }) {
  const [, setOwnedPhotos] = useAtom(ownedPhotosAtom);
  const [, setOwnedClips] = useAtom(ownedClipsAtom);
  const { data: session } = useSession();
  const { data: user } = trpc.users.getAuthenticatedUser.useQuery(undefined, {
    enabled: !!session,
    staleTime: Infinity,
  });

  const { data: userStripeData } = trpc.stripe.getStripeUserPlanData.useQuery(undefined, {
    enabled: !!session,
    staleTime: Infinity,
  });

  // If there is no user and no session then unidentify the user
  // This is fine to run more than once as it will just clear the user for tracking and sentry
  useEffect(() => {
    if (!user && !session) {
      unidentifyUser();
    }
  }, [user, session]);

  // When the user object changes identify the user
  // This will update Sentry and push new user data to segment
  useEffect(() => {
    if (user) {
      identifyUser(user, userStripeData?.userPlan?.name);
    }
  }, [user]);

  const { data, isLoading } = trpc.transactions.listTransactions.useQuery(undefined, {
    enabled: !!user && !!session?.user,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isLoading || !user) return setOwnedPhotos(null);
    const photoIds =
      data?.transactions?.filter((t) => t.type === 'photo').map((t) => t.asset_id) || [];
    const clipsIds =
      data?.transactions?.filter((t) => t.type === 'clip').map((t) => t.clip_id) || [];
    setOwnedPhotos([...new Set(photoIds)]); // remove duplication
    setOwnedClips([...new Set(clipsIds)]);
  }, [data, isLoading, user, setOwnedPhotos]);

  // This handles fetching the isEditorial status for photos for admins
  // We do this in the withAuth so it has a shared queue for the whole app
  // We could probably move this into a separate provider
  const context = trpc.useContext();
  const [isLoadingEditorial, setIsLoadingEditorial] = useState(false);
  const [isEditorialMap, setIsEditorialMap] = useAtom(isEditorialMapAtom);
  const [isEditorialQueue, setIsEditorialQueue] = useAtom(isEditorialQueueAtom);
  useEffect(() => {
    if (!user?.is_admin || isLoadingEditorial) return;
    const isEditorialToFetch = isEditorialQueue.filter(
      (id) => typeof isEditorialMap[id] === 'undefined'
    );
    if (isEditorialToFetch.length === 0) return;
    setIsLoadingEditorial(true);
    context.client.photos.isEditorial.query(isEditorialQueue).then((res) => {
      setIsEditorialQueue((prev) => prev.filter((id) => !res[id]));
      setIsEditorialMap((prev) => ({ ...prev, ...res }));
      setIsLoadingEditorial(false);
    });
  }, [user, isLoadingEditorial, isEditorialMap, isEditorialQueue]);

  // It seems like we are unidentifying users which resets the anonymous ID
  // We should only do this if the user has been identified already and we need to clear them
  const [isIdentified, setIsIdentified] = useState(false);

  const unidentifyUser = () => {
    if (!isIdentified) return;
    setIsIdentified(false);
    Sentry.setUser(null);
    analytics.reset();
  };

  const identifyUser = (user: users, plan?: string) => {
    if (user) setIsIdentified(true);
    const fullName = (user.firstname + ' ' + user.lastname).trim();

    // Help scout beacon - init and identify
    const beaconUser = {
      id: user.id,
      email: user.email,
      name: fullName,
    };
    if (typeof window['Beacon'] !== 'undefined') {
      window['Beacon']('init', process.env.NEXT_PUBLIC_HELPSCOUT_BEACON);
      window['Beacon']('identify', beaconUser);
    }

    // identify user to segment
    const flatIdentifyUser = flatten(user) as Record<string, any>;
    const segmentUser = {
      userId: user.id,
      ...flatIdentifyUser,
    };

    analytics.identify(user?.id?.toString(), segmentUser);

    // identify user to sentry
    const userIdString = user.id?.toString();
    Sentry.setUser({
      email: user.email,
      id: userIdString,
      username: fullName,
    });

    // identify user to clarity
    if (isBrowser) {
      window['clarity']?.('identify', userIdString);
      window['clarity']?.('set', 'email', user.email);
      window['clarity']?.('set', 'plan', plan);
    }
  };

  return children;
}
