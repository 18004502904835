import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import useLockedBody from '../../hooks/useLockedBody';
import { TPhoto } from '../../interfaces/photos';
import { TTag } from '../../interfaces/tags';
import { colorManagerAtom, showSearchAtom } from '../../store/uiStore';
import TagList from '../search/TagList';
import useMediaSearch from '@/hooks/useMediaSearch';
import _ from 'lodash';

const SearchOverlay = () => {
  const [showSearch, setShowSearch] = useAtom(showSearchAtom);
  const [colorManager, setColorManager] = useAtom(colorManagerAtom);
  const [, setLocked] = useLockedBody();
  const [hoveredTag, setHoveredTag] = useState<TTag>();
  const router = useRouter();
  const { medias, isLoading } = useMediaSearch(hoveredTag?.name || '', 'overlay search');
  const [tagPhotos, setTagPhotos] = useState([]);

  useEffect(() => {
    if (hoveredTag && !isLoading) {
      setTagPhotos(
        hoveredTag
          ? _.shuffle(medias.filter((m) => m.media_type === 'photo') as unknown as TPhoto[])
          : []
      );
    } else {
      setTagPhotos([]);
    }
  }, [hoveredTag, isLoading]);

  // Hide search when you navigate anywhere
  useEffect(() => {
    setShowSearch(false);
  }, [router.asPath, setShowSearch]);

  useEffect(() => {
    if (showSearch) {
      setColorManager({
        ...colorManager,
        background: 'bg-orange-400',
        text: 'text-white',
        navActive: true,
      });
      setLocked(true);
    }

    return () => {
      setLocked(false);
      setColorManager({
        ...colorManager,
        background: 'bg-black',
        text: 'text-white',
        navActive: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch]);

  const handleTagSelected = (tag) => {
    router.push(`/search?q=${tag.name}`);
  };

  const handleHover = (tag) => {
    setHoveredTag(tag);
  };

  return (
    <AnimatePresence>
      {showSearch && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween', duration: 0.3, ease: 'easeOut' }}
          className={clsx(
            'pt-6 pb-12 4xl:pt-12 fixed top-[112px] right-0 bottom-0 flex flex-col left-0 px-[64px] z-40 bg-orange-400 w-full',
            showSearch ? 'opacity-100' : 'opacity-0'
          )}
        >
          <div className="relative z-10 max-w-[1500px]">
            <TagList onTagSelected={handleTagSelected} onHover={handleHover} />
          </div>
          {tagPhotos && <ImageOverlapDisplay images={tagPhotos} />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SearchOverlay;

const ImageOverlapDisplay = ({ images }: { images: TPhoto[] | undefined }) => {
  return (
    <div className="h-full flex flex-col justify-end">
      <div className="relative w-full h-full max-h-full box-border self-stretch">
        {images && images.length >= 4 && (
          <>
            <div
              className="bg-cover absolute h-[60%] z-40 bottom-0 right-0 aspect-[3/4]"
              style={{
                backgroundColor: images[0].dominant_hex_code ?? '',
                backgroundImage: 'url(' + images[0].imgix.links.small_image_url + ')',
              }}
            >
              <div
                className="bg-cover absolute top-0 left-0 h-full w-full"
                style={{
                  backgroundImage: 'url(' + images[0].imgix.links.large_image_url + ')',
                }}
              ></div>
            </div>
            <div
              className="bg-cover absolute h-[90%] bottom-[4%] right-0 translate-x-[-30%] aspect-[3/4] z-30 mt-[30px]"
              style={{
                backgroundColor: images[1].dominant_hex_code ?? '',
                backgroundImage: 'url(' + images[1].imgix.links.small_image_url + ')',
              }}
            >
              <div
                className="bg-cover absolute top-0 left-0 h-full w-full"
                style={{
                  backgroundImage: 'url(' + images[1].imgix.links.large_image_url + ')',
                }}
              ></div>
            </div>
            <div
              className="bg-cover absolute h-[70%] bottom-0 right-0 translate-x-[-120%] aspect-[3/4] z-20"
              style={{
                backgroundColor: images[2].dominant_hex_code ?? '',
                backgroundImage: 'url(' + images[2].imgix.links.small_image_url + ')',
              }}
            >
              <div
                className="bg-cover absolute top-0 left-0 h-full w-full"
                style={{
                  backgroundImage: 'url(' + images[2].imgix.links.large_image_url + ')',
                }}
              ></div>
            </div>
            <div
              className="bg-cover absolute h-[60%] bottom-[4%] right-0 translate-x-[-220%] aspect-[3/4] z-10 mt-[30px]"
              style={{
                backgroundColor: images[3].dominant_hex_code ?? '',
                backgroundImage: 'url(' + images[3].imgix.links.small_image_url + ')',
              }}
            >
              <div
                className="bg-cover absolute top-0 left-0 h-full w-full"
                style={{
                  backgroundImage: 'url(' + images[3].imgix.links.large_image_url + ')',
                }}
              ></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
