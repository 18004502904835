import { createTRPCProxyClient, httpLink, TRPCLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import superjson from 'superjson';
import type { AppRouter } from '../server/routers/_app';
import { AnyRouter } from '@trpc/server';
import { observable } from '@trpc/server/observable';
import { MAINTENANCE_ERROR_MESSAGE } from './constants';

const customLink: TRPCLink<AnyRouter> = () => {
  return ({ next, op }) => {
    return observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err) {
          if (
            err.message === MAINTENANCE_ERROR_MESSAGE &&
            !window.location.pathname.includes('/maintenance')
          ) {
            window.location.href = '/maintenance';
          }
          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
  };
};

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

const config = {
  transformer: superjson,
  links: [
    customLink,
    httpLink({
      url: `${getBaseUrl()}/api/v1`,
    }),
  ],
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return config;
  },
});

// For use outside react
export const trpcVanilla = createTRPCProxyClient<AppRouter>(config);
