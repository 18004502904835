import clsx from 'clsx';
import { useAtom } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import useAdmin from '../../hooks/useAdmin';
import useNavHelper from '../../hooks/useNavHelper';
import useScroll from '../../hooks/useScroll';
import { colorManagerAtom, showSearchAtom, slimNavAtom } from '../../store/uiStore';
import SearchInput from '../search/SearchInput';
import SearchOverlay from './SearchOverlay';
import DTSLogo from './icons/DTSLogo';

interface NavBarProps {
  gradient?: boolean;
  disableSearchOverlay?: boolean;
}

const NavBar = ({ gradient = false, disableSearchOverlay = false }: NavBarProps) => {
  const { showStickyNav } = useNavHelper();
  const [slimNav] = useAtom(slimNavAtom);
  const { scrollY } = useScroll();
  const [colorManager] = useAtom(colorManagerAtom);
  const showAdmin = useAdmin();
  const [showSearch] = useAtom(showSearchAtom);

  return (
    <>
      <div
        className={clsx(
          'grid grid-cols-5 items-center pt-[32px] pb-[36px] z-50 sticky top-0 mx-[-64px] px-[64px] duration-300 transition-all ease-out will-change-transform',
          gradient && !showSearch ? 'bg-gradient-to-b from-black' : colorManager.background,
          colorManager?.text ? colorManager.text : 'text-white',
          !scrollY && !showSearch && !gradient && 'bg-opacity-70 backdrop-blur-xl'
        )}
        style={{
          transform: showStickyNav ? 'translate3d(0, 0%, 0)' : 'translate3d(0, -100%, 0)',
        }}
      >
        <div className="col-span-1 cursor-pointer">
          <Link href="/" className="contents">
            <DTSLogo fill={colorManager.text === 'text-black' ? 'black' : 'white'} />
          </Link>
        </div>

        <div className={clsx('flex justify-center text-sm col-span-3', slimNav && 'invisible')}>
          <NavItem title="Projects" link="/projects" activePaths={['/project']} />
          <NavItem title="Explore" link="/explore" />
          <NavItem
            title="Collective"
            link="/collective"
            activePaths={['/creator', '/collection/collective']}
          />
          <NavItem title="Moodboard" link="/moodboard" activePaths={['/collection']} />
          <NavItem title="Account" link="/account" />
          {showAdmin && <NavItem title="Admin" link="/admin" />}
        </div>

        <div className={clsx('col-span-1 flex justify-end', slimNav && 'invisible')}>
          <SearchInput />
        </div>
      </div>

      {!disableSearchOverlay && <SearchOverlay />}
    </>
  );
};

export const NavItem = ({
  title,
  link,
  activePaths = [],
}: {
  title: string;
  link: string;
  activePaths?: string[];
}) => {
  const [colorManager] = useAtom(colorManagerAtom);
  const [showSearch, setShowSearch] = useAtom(showSearchAtom);
  const [colorClasses, setColorClasses] = useState('');

  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const pathMatch = [...activePaths, link]?.some((path) => router.pathname.startsWith(path));
    setIsActive(pathMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    if (isActive) {
      if (colorManager.navActive) {
        setColorClasses('text-white border-transparent');
      } else {
        setColorClasses('text-orange-400 border-orange-400');
      }
    }
  }, [colorManager, isActive]);

  const handleShowSearch = () => {
    if (showSearch) {
      setShowSearch(false);
    }
  };

  return (
    <Link href={link} className="contents">
      <p
        onClick={handleShowSearch}
        className={clsx(
          'px-3 py-2 mx-1 leading-none border cursor-pointer',
          isActive ? colorClasses + ' border rounded-[100%]' : 'border-transparent'
        )}
      >
        {title}
      </p>
    </Link>
  );
};

export default NavBar;
